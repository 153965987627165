import { render, staticRenderFns } from "./Menu.vue?vue&type=template&id=2954496c&scoped=true&"
import script from "./Menu.vue?vue&type=script&lang=ts&"
export * from "./Menu.vue?vue&type=script&lang=ts&"
import style0 from "./Menu.vue?vue&type=style&index=0&id=2954496c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2954496c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ThemeSwitch: require('/vercel/path0/components/ThemeSwitch.vue').default,Icon: require('/vercel/path0/components/Icon.vue').default,NuxtDynamic: require('/vercel/path0/node_modules/@blokwise/dynamic/src/components/Dynamic.vue').default})
