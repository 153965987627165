
export default {
  computed: {
    isDarkModeActive() {
      return this.$colorMode.value === 'dark'
    },
  },
  methods: {
    changeTheme(event) {
      this.$colorMode.preference = (event.target.checked === true) ? 'dark' : 'light'
    },
  },
}
