
import {
  Component,
  Vue,
} from 'nuxt-property-decorator'
import { directive as onClickaway } from 'vue-clickaway'
import { MainMenuEntity } from '~/graphql/generated'
import mainMenuQuery from '~/graphql/queries/mainMenu.graphql'

@Component({
  directives: {
    onClickaway,
  },
  fetchOnServer: true,
})
export default class Menu extends Vue {
  mainMenu: MainMenuEntity = null

  get isMenuVisible() {
    return this.$store.state.menuVisible
  }

  getComponentName(typeName: string): string {
    return typeName.replace('Component', '')
  }

  hideMenu() {
    this.$store.commit('setMenuVisible', false)
  }

  showCookieSettings() {
    if (window.klaro) {
      window.klaro.show(undefined, true)
      this.hideMenu()
    }
  }

  away(event) {
    if (event.target.classList.contains('menu-button')) {
      return
    }
    if (this.isMenuVisible) {
      this.hideMenu()
    }
  }

  async fetch() {
    this.mainMenu = await this.$apolloProvider.defaultClient.query({
      query: mainMenuQuery,
    }).then((response) => response.data.mainMenu.data.attributes)
  }
}
